<template>
  <v-card class="card text-center">
    <h3 class="card-text">
      {{ $t("create_campaign_page.advertising_goal_step_title") }}
    </h3>
    <v-list>
      <v-list-item class="mt-3 list-item">
        <span>
          <img :src="phone" height="40" width="40" />
        </span>
        <h3 class="ml-5 list-item-text font-family-raleway-medium">
          {{ $t("create_campaign_page.advertising_goal_step_services1") }}
        </h3>
        <v-spacer></v-spacer>
        <v-checkbox v-model="advertisingGoals.getCalls" color="blue accent-4" />
      </v-list-item>
      <v-list-item class="mt-3 list-item">
        <span>
          <img :src="groupUser" height="40" width="40" />
        </span>
        <h3 class="ml-5 list-item-text font-family-raleway-medium">
          {{ $t("create_campaign_page.advertising_goal_step_services2") }}
        </h3>
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="advertisingGoals.getOnlineSales"
          color="blue accent-4"
        />
      </v-list-item>
      <v-list-item class="mt-3 list-item">
        <span>
          <img :src="location" height="40" width="40" />
        </span>
        <h3 class="ml-5 list-item-text font-family-raleway-medium">
          {{ $t("create_campaign_page.advertising_goal_step_services3") }}
        </h3>
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="advertisingGoals.getOfflineVisits"
          color="blue accent-4"
        />
      </v-list-item>
    </v-list>
    <v-btn
      class="font-weight-bold button next-btn font-family-raleway-medium"
      style="width: 100% !important"
      @click="handleClick"
      :disabled="
        !advertisingGoals.getCalls &&
        !advertisingGoals.getOfflineVisits &&
        !advertisingGoals.getOnlineSales
      "
      >{{ $t("create_campaign_page.next_button") }}
    </v-btn>
  </v-card>
</template>
<script>
import phone from "@/assets/img/phone.svg";
import groupUser from "@/assets/img/groupUser.svg";
import location from "@/assets/img/location.svg";

export default {
  name: "MainAdverisingGoals",
  data: () => ({
    phone,
    groupUser,
    location,
    advertisingGoals: {
      getCalls: true,
      getOnlineSales: true,
      getOfflineVisits: true,
    },
    campaign: {},
  }),
  created() {
    const cam = localStorage.getItem("campaign");
    const parseToObj = JSON.parse(cam);
    if (parseToObj?.goals) {
      this.advertisingGoals = parseToObj.goals;
    }
    localStorage.removeItem("allKeywords");
  },
  methods: {
    handleClick() {
      const advertisingGoals = this.advertisingGoals;
      localStorage.setItem(
        "campaign",
        JSON.stringify({
          ...this.campaign,
          goals: { ...advertisingGoals },
        })
      );
      this.$router.push("basic-info");
    },
  },
};
</script>
<style lang="scss" scoped>
.list-item {
  background-color: #f9f9f9;
  border-radius: 16px;
  padding: 10px 20px !important;

  .list-item-text {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}

.next-btn {
  width: 100%;
  padding: 30px 0 !important;
  margin-top: 30px;
}

.card {
  box-shadow: none !important;
  border-radius: 20px !important;
  padding: 40px !important;
  @media screen and (max-width: 600px) {
    padding: 20px 15px !important;
  }
  @media screen and (max-width: 450px) {
    padding: 20px 10px !important;
  }

  .card-text {
    font-size: 20px !important;
  }
}

</style>
